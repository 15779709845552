import styled from 'styled-components';
import variables from '../../variables';

export const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  width: 100%;
  padding: .3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0;
  margin-top: 4em;
`;


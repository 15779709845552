import styled, { css } from "styled-components";
import { ifProp, fromProp } from "ifprop";
import { StyledH1, StyledH4 } from "./Typography";
import React from "react";
import mediaQueryBuilder from "helpers/mediaQueryBuilder";
import variables from "../variables";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${ifProp({ flexGrow: 1 })};
  justify-content: ${ifProp({
    spaceBetween: "space-between",
    spaceAround: "space-around",
    center: "center"
  })};
  align-items: ${ifProp({
    stretch: "stretch",
    start: "flex-start",
    middle: "center"
  })};
  width: ${ifProp({ half: "45%" })};
  min-width: 30%;
  height: ${ifProp({ fullHeight: "100%" })};
  ${ifProp({
    desktopOnly: css`
      @media screen and (max-width: 500px) {
        display: none;
      }
    `
  })}
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  grid-column-gap: 2em;
`;

export const Box = styled.div`
  border: 1px solid grey;
  background: #f7fcf8;
  padding: 1em;
  border-radius: 2px;
  margin: 1em;
`;

export const DesktopFloatingColumn = styled(Column)`
  float: right;
  max-width: 300px;
  @media screen and (max-width: 500px) {
    display: none;
    li {
      font-size: 0.8em;
    }
    max-width: 150px;
    ${Box} {
      padding: 0.5em;
    }
  }
`;

export const TwoColumnFullPage = styled.div`
  display: grid;
  grid-gap: 2em;
  @media screen and (min-width: 600px) {
    grid-template-columns: 60% 40%;
    ${Column} {
      min-width: 190px;
    }
  }
`;

const StyledHr = styled.hr`
  height: 3px;
  background-color: ${variables.primaryColorLight};
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const SeparatedSection = ({ children }) => {
  return (
    <StyledSection extraMargin>
      <StyledHr />
      {children}
      <StyledHr />
    </StyledSection>
  );
};

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${ifProp({
    spaceBetween: "space-between",
    spaceAround: "space-around",
    center: "center",
    flexEnd: "flex-end"
  })};
  align-items: ${ifProp({
    stretch: "stretch",
    start: "flex-start",
    middle: "center"
  })};
  width: 100%;
  padding: ${ifProp({ padded: "1em" })};
  position: relative;
  flex-wrap: wrap;
  ${ifProp({
    columnOnMobile: css`
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    `
  })};
`;

const StyledHero = styled.div`
  width: 100%;
  height: 20em;
  position: relative;
  margin-bottom: 2em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

StyledHero.Text = styled.div`
  z-index: 2;
`;

StyledHero.Img = styled.img`
  width: 100%;
  max-width: 1000px;
  min-height: 400px;
  background-color: tan;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  max-height: 400px;
  object-fit: cover;
  filter: brightness(0.8);
  ${mediaQueryBuilder.heightTablet(css`
    min-height: none;
  `)}
`;

StyledHero.Title = styled(StyledH1)`
  color: white;
  margin: 0 !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

StyledHero.SubTitle = styled(StyledH4)`
  color: white;
  max-width: 90%;
  margin: auto;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
`;

export { StyledHero };

const StyledInside = styled.section`
  max-width: 1000px;
  margin: 2em auto 3em auto;
  ${ifProp({
    extraMargin: css`
      margin: 5em auto 6em auto;
    `
  })}
  font-size: 0.8rem;
  ${mediaQueryBuilder.widthTablet(css`
    font-size: 1rem;
  `)}
`;

export const StyledArticle = styled.article`
  background: white;
  padding: 1em;
  margin-right: 20px;
  ${mediaQueryBuilder.widthMobile(css`
    margin-right: inherit;
  `)}
  ${ifProp({
    long: `
      font-size: 1.3rem;
      max-width: 800px;
      margin: auto;

      ${mediaQueryBuilder.widthMobile(css`
        font-size: 1.6rem;
      `)}
    `
  })}
`;

export const StyledWrapper = styled.div`
  // transform: scale(${fromProp("scale")});
  // transform-origin: 0 0;
`;

export const StyledSection = ({ children, ...props }) => (
  <StyledInside {...props}>{children}</StyledInside>
);

export const ToolTipBox = styled.div`
  margin: 1em 0.2em -1em 0.2em;
`;

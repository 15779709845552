import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";
import variables from "../../variables";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router" // this helps tracking the location

export const CookieBanner = (props) => {
  const location = useLocation();
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="RealSafeCars_cookie-accept"
      onAccept={() => {
        initializeAndTrack(location)}}
      buttonStyle={{
        background: "white",
        color: variables.primaryColor,
        fontSize: "13px",
      }}
      expires={150}
    >
      This site collects cookies to improve your experience.
    </CookieConsent>
  );
};

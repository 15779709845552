import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StyledFooter } from "./Footer.styles";
import { BareLink } from "styles/Buttons";

export const Footer = (props) => {
  return (
    <StyledFooter>
      <span>Copyright 2021 RealSafeCars LLC</span>
      <BareLink>
        <Link to="/terms">terms & conditions</Link>
      </BareLink>
    </StyledFooter>
  );
};

import styled, { css } from 'styled-components';
import { ifProp, fromProp } from 'ifprop';
import mediaQueryBuilder from '../helpers/mediaQueryBuilder';
import variables from '../variables';

export const StyledH1 = styled.h1`
  font-family: 'Adamina', serif;
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
  ${mediaQueryBuilder.heightDesktop(css`
    margin-top: 6rem;
    margin-bottom: 3rem;
    font-size: 2.5em;
  `)}
`;

export const StyledH2 = styled.h2`
  font-family: 'Adamina', serif;
  font-size: 1.5em;
  margin-bottom: 2rem;
  ${ifProp({ noMargin: `margin: 0;` })}
  text-align: center;
  width: 100%;
  ${mediaQueryBuilder.heightDesktop(css`
    font-size: 2.5em;
  `)}
`;

export const StyledH3 = styled.h3`
  font-family: 'Adamina', serif;
  font-size: 1.2em;
  margin-bottom: 1.5rem;
  ${ifProp({ noMargin: `margin: 0;` })}
  text-align: center;
  width: 100%;
  ${mediaQueryBuilder.heightDesktop(css`
    font-size: 2.5em;
  `)}
`;

export const StyledH6 = styled.h6`
  color: ${variables.primaryColorLight};
  font-weight: bold;
  font-size: 1.5em;
  border-bottom: 1px solid ${variables.primaryColorLight};
  margin-bottom: .5em;
  width: 100%;
  ${mediaQueryBuilder.maxWidthMobile(css`
    font-size: ${ifProp({ smallOnMobile: '1.2em' })};
  `)}
`;

export const P = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
  overflow: auto;
  ${props => props.center && `text-align: center`}
`;

export const NoWrap = styled.span`
  overflow: auto;
  white-space: no-wrap;
  word-break: keep-all;
`;

export const Caption = styled.caption`
  width: 100%;
  display: block;
  font-size: .7rem;
  font-style: italic;
  line-height: 1em;
  text-align: left;
  strong {
    font-weight: bold;
    font-style: normal;
  }
`;

export const SmallP = styled(P)`
  font-size: .9rem;
  line-height: 1.3rem;
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const H3 = styled.h3`
  width: 100%;
  text-align: center;
  margin-top: 3em;
  margin-bottom: 2em;
`;

export const StyledH4 = styled.h4`
  text-align: center;
  text-transform: capitalize;
  font-size: 1.3em;
`;

export const Ul = styled.ul``;
export const Li = styled.li`
  list-style: none;
  *:last-child {
    margin-bottom: inherit;
  }
`;

export const StyledA = styled.a`
  color: ${variables.primaryColorLight};
`;

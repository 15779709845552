import styled, { css } from 'styled-components';
import { ifProp } from 'ifprop';
import variables from '../variables';

export const BareLink = styled.a`
  text-decoration: underline;
  color: inherit;
  position: relative;
  &:hover {
    color: ${variables.primaryColorLight};
  }
`;

import React from "react";
import { Body, Main } from "./SharedLayout.styles";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { useStaticQuery, graphql } from "gatsby";
import { CookieBanner } from "components/CookieBanner";
import "../../components/layout.css"

export const SharedLayout: React.FC = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Body>
      <Header pageContext={pageContext} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Main>{children}</Main>
      <Footer />
      <CookieBanner />
    </Body>
  );
};

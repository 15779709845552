import { ifProp } from "ifprop";
import mediaQueryBuilder from "helpers/mediaQueryBuilder";
import styled, { css } from 'styled-components';

export const Body = styled.div`
  padding-top: 1.7em;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  font-family: "Assistant", sans-serif;
  padding-top: 3.7em;
`;

export const Main = styled.main`
  max-width: 90%;
  width: 800px;
  margin: auto;
`;
